import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { filterSearch, twWidthUnits } from '@sparte/utils';
import { Dropdown, DropdownOptions, initFlowbite } from 'flowbite';
import { v4 as uuidv4 } from 'uuid';
/**
 * @input value: string;
 * @input viewValue: string;
*/
export type SparteAutocompleteItem = {
  value: string,
  viewValue: string,
  searchTerm?: string,
  selected?: boolean,
}

@Component({
  selector: 'sparte-autocomplete',
  templateUrl: './sparte-autocomplete.component.html',
  styleUrls: ['./sparte-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparteAutocompleteComponent implements AfterViewInit {

  public _menu: string = uuidv4();
  public _btn: string = uuidv4();

  @Input() disabled: boolean = false;
  /**
   * @description
   * @default []
   * @example
   * <sparte-form-dropdown [items]="items">
   * </sparte-form-dropdown>
   * @input items: SparteAutocompleteItem[] = [];
   */
  @Input() items: SparteAutocompleteItem[] = [];

  /**
   * @description
   * @default 'Sélectionner une valeur'
   * @example
   * <sparte-form-dropdown [placeholder]="'Sélectionner une valeur'">
   * </sparte-form-dropdown>
   * @input placeholder: string;
   */
  @Input() placeholder: string;


  /**
   * @description
   * @default 'Sélectionner une valeur'
   * @example
   * <sparte-form-dropdown [defaultPlaceholder]="'Sélectionner une valeur'">
   * </sparte-form-dropdown>
   * @input defaultPlaceholder: string = 'Sélectionner une valeur';
   */
  @Input() defaultPlaceholder: string = 'Sélectionner une valeur';

  /**
   * @description
   * @default 'Ajouter: '
   * @example
   * <sparte-form-dropdown [addNewPlaceholder]="'Ajouter : '">
   * </sparte-form-dropdown>
   * @input addNewPlaceholder: string = 'Ajouter : ';
   */
  @Input() addNewPlaceholder: string = 'Ajouter : ';

  /**
   * @description return an array of selected items values
   * allow multiple selection of items in the dropdown
   * @default false
   * @example
   * <sparte-form-dropdown multiSelect>
   * </sparte-form-dropdown>
   * @input multiSelect: boolean = false;
   */
  @Input() set multiSelect(param) { this._multiSelect = true; }

  @Input() set addNew(param) { this._addNew = true; }
  @Input() set noBorder(param) { this._noBorder = true; }

  /**
   * @description
   * @default 'bottom'
   * @example
   * <sparte-form-dropdown [placement]="'bottom'">
   * </sparte-form-dropdown>
   * @input placement: "top" | "right" | "bottom" | "left" = 'bottom';
   * @see https://flowbite.com/docs/components/dropdowns/#placement
   */
  @Input() placement: "top" | "right" | "bottom" | "left" = 'bottom';

  /**
   * @description custom width of the dropdown
   * @default 'w-80'
   * @example
   * <sparte-form-dropdown [width]="'w-80'">
   * </sparte-form-dropdown>
   * @input width: string = 'w-80';
   * @see https://tailwindcss.com/docs/width
   */
  @Input() width: twWidthUnits = 'w-80';

  /**
   * @description
   * @default false
   * @example
   * <sparte-form-dropdown [red]="true">
   * </sparte-form-dropdown>
   * @input red: boolean = false;
   */
  @Input() red: boolean = false;

  @Output() selectedItems: EventEmitter<string[] | string> = new EventEmitter<string[] | string>();

  @Output() addNewEvent: EventEmitter<string> = new EventEmitter<string>();

  public _multiSelect = false;
  public dropdown: Dropdown;
  public searchTerm: string = '';
  public selectedItem: string = '';
  public _addNew = false;
  public _noBorder = false;

  constructor(
    private cdref: ChangeDetectorRef,
  ) { }

  // i dare you to find a better way to do this
  get parentWidth(): string {
    return `width: ${document.getElementById(this._btn).offsetWidth}px`;
  }

  public ngAfterViewInit(): void {
    const $triggerEl = document.getElementById(this._btn);
    const $targetEl = document.getElementById(this._menu);
    const options: DropdownOptions = {
      placement: this.placement,
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    }

    if (!this.disabled) this.dropdown = new Dropdown($targetEl, $triggerEl, options);
    this.cdref.detectChanges();
  }

  get filteredItems(): SparteAutocompleteItem[] {
    return this.items.filter((item) => filterSearch(this.searchTerm, item.searchTerm));
  }

  get selectedItemsList(): string[] {
    return this.items.filter((item) => item.selected).map((item) => item.value);
  }

  get _placeholder(): string {
    if (this._multiSelect) {
      return this.selectedItemsList.length > 0 ? `${this.selectedItemsList.length} élément(s) sélectionné(s)` : (this.placeholder || this.defaultPlaceholder);
    }
    else {
      return this.placeholder || this.items.find((item) => item.selected)?.viewValue || this.defaultPlaceholder;
    }
  }

  handleSelect(item: SparteAutocompleteItem) {
    if (!this._multiSelect) return;
    const realItem = this.items.find((i) => i.value === item.value);
    realItem.selected = !realItem.selected;
    this.selectedItems.emit(this.selectedItemsList);
    this.cdref.detectChanges();
  }

  handleClick(item: SparteAutocompleteItem) {
    if (this._multiSelect) return;
    this.searchTerm = '';
    if (this.selectedItem === item.value) {
      this.handleClear();
      return;
    };
    this.placeholder = item.viewValue;
    this.selectedItem = item.value;
    this.selectedItems.emit(item.value);
    this.dropdown?.hide();
    this.cdref.detectChanges();
  }

  handleKeyDown() {
    this.dropdown.show();
    this.cdref.detectChanges();
  }

  handleClear() {
    this.searchTerm = '';
    this.selectedItem = '';
    this.placeholder = '';
    this.selectedItems.emit(this._multiSelect ? [] : '');
    this.cdref.detectChanges();
  }

  handleAddNew() {
    this.selectedItem = this.items.find((item) => item.searchTerm === this.searchTerm)?.value;
    this.addNewEvent.emit(this.searchTerm);
    this.dropdown?.hide();
  }

  trackByFn(index: number, item: any): number {
    return index;
  }
}
